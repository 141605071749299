<template>
    <section class="layout-container-app">
        <router-view />
    </section>
</template>

<script>
export default {
    name: 'movimentacoes',
    components: {},
    computed: {},
    created() {
        this.$router.push({ name: 'filtros-requisicoes' });
    }
};
</script>

<style scoped>
.layout-container-app {
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.layout-container-header {
    height: fit-content;
}

.layout-container-body {
    flex-grow: 1;
    overflow: auto;
}
</style>
